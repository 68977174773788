














































































import { Component, Vue } from 'vue-property-decorator'
import {
    apiFaceSheetSenderEdit,
    apiFaceSheetSenderDetail,
    apiFaceSheetSenderAdd
} from '@/api/application/express'
import AreaSelect from '@/components/area-select.vue'
@Component({
    components: {
        AreaSelect
    }
})
export default class PrintEdit extends Vue {
    /** S Data **/

    identity: Number = 1

    status: any = ''

    // 数据
    form: any = {
        name: '', //	是	string
        province_id: '', //	是	int	省id
        city_id: '', //	是	int	市id
        district_id: '', //	是	int	区id
        address: '' //	是	string	地址
    }

    // 表单验证
    rules: any = {
        name: [
            { required: true, message: '请输入发件人名称', trigger: 'blur' }
        ],
        mobile: [
            { required: true, message: '请输入发件人手机', trigger: 'blur' }
        ],
        province_id: [
            { required: true, message: '请选择地区', trigger: 'change' }
        ],
        address: [
            { required: true, message: '请输入发件人详细地址', trigger: 'blur' }
        ]
    }

    /** E Data **/

    /** S Methods **/

    // 获取打印机类型数据
    async getSenderDetail() {
        const res = await apiFaceSheetSenderDetail({ id: this.identity })
        this.form = res
    }

    // 点击表单提交
    onSubmit(formName: string) {
        // 验证表单格式是否正确
        const refs = this.$refs[formName] as HTMLFormElement
        refs.validate((valid: boolean): any => {
            if (!valid) {
                return
            }
            if (!this.identity) {
                this.handleSenderAdd()
            } else {
                this.handleSenderEdit()
            }
        })
    }

    handleSenderAdd() {
        const params = this.form
        apiFaceSheetSenderAdd({ ...params }).then(() => {
            setTimeout(() => this.$router.go(-1), 500)
        })
    }

    // 编辑打印机
    handleSenderEdit() {
        const params = this.form
        const id: number = this.identity as number
        apiFaceSheetSenderEdit({ ...params, id }).then(() => {
            setTimeout(() => this.$router.go(-1), 500)
        })
    }

    /** E Methods **/

    /** S Life Cycle **/
    created() {
        const query: any = this.$route.query
        this.identity = query.id
        if (this.identity) {
            this.getSenderDetail()
        }
    }
    /** E Life Cycle **/
}
